/**
 * Copyright(c) 2021 Mozanta Technologies Private Ltd.
 *
 * All rights reserved.
 *
 * This software is the confidential and proprietary information of Mozanta ("Confidential
 * Information"). You shall not disclose such Confidential Information and shall use it only in
 * accordance with the terms of the contract agreement you entered into with Mozanta.
 *
 *  app
 *
 * @author Anokh J Ajai
 */

import PrivateRoute from '@common_components/PrivateRoute';
import PublicRouter from '@common_components/PublicRouter';
import { Router } from '@gatsbyjs/reach-router'
import Cart from '@routes/Cart';
import CreatePassword from '@routes/CreatePassword';
import ForgotPassword from '@routes/ForgotPassword';
import Help from '@routes/Help';
import Home from '@routes/Home';
import ImpersonateUser from '@routes/ImpersonateUser';
import Login from '@routes/Login';
import MyAccount from '@routes/my-account';
import OrderConfirmation from '@routes/OrderConfirmation';
import OrderDetail from '@routes/OrderDetail';
import OrderHistoryLayout from '@routes/OrderHistoryLayout';
import OrderProposalLayout from '@routes/OrderProposalLayout';
import OrderProposalDetailsLayout from '@src/routes/OrderProposalDetailsLayout';
import PolicyLayout from '@routes/PolicyLayout';
import Pricat from '@routes/Pricat';
import PDP from '@routes/ProductDetails';
import ProductListPage from '@routes/ProductList';
import ResetPassword from '@routes/ResetPassword';
import Shipping from '@routes/Shipping';
import SubAccounts from '@routes/SubAccounts';
import SwitchAccount from '@routes/SwitchAccount';
import TermsLayout from '@routes/TermsLayout';
import Wishlist from '@routes/Wishlist';
import WishlistEdit from '@routes/WishlistEdit';
import { isListNotEmpty } from '@utils/commonUtils';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import NotFound from './404';

const MainRoutes = props => (props.children);

const App = props => {
  const { allAvailableLocations } = useStaticQuery(graphql`
  {
    allAvailableLocations {
      nodes {
        data {
          brandId
          locationId
          brandName
          locationName
          storefrontRoute
          market
        }
      }
    }
  }
`);
  return (
    <>
      <Router>
        {isListNotEmpty(allAvailableLocations?.nodes)
          ? (
            allAvailableLocations?.nodes.map(brand => {
              const { data } = brand || {};
              return (
                data?.storefrontRoute
                  ? (
                    <MainRoutes path={data.storefrontRoute}>
                      {/* Private Routes */}
                      <PublicRouter default path='/404' component={NotFound} />
                      <PrivateRoute path='/' component={Home} />
                      <PrivateRoute path='/sub-accounts' component={SubAccounts} />
                      <PrivateRoute path='/my-account' component={MyAccount} />
                      <PrivateRoute path='/product-list' component={ProductListPage} />
                      <PrivateRoute path='/product-list/:id' component={ProductListPage} />
                      <PrivateRoute path='/:classification/product-list' component={ProductListPage} />
                      <PrivateRoute path='/:classification/product-list/:id' component={ProductListPage} />
                      <PrivateRoute path='/cart' component={Cart} />
                      <PrivateRoute path='/product-details/:id' component={PDP} />
                      <PrivateRoute path='/wishlist' component={Wishlist} />
                      <PrivateRoute path='/wishlist/edit' component={WishlistEdit} pageProps={props} />
                      <PrivateRoute path='/shipping' component={Shipping} pageProps={props} />
                      <PrivateRoute path='/order-confirmation' component={OrderConfirmation} pageProps={props} />
                      <PrivateRoute path='/orders' component={OrderHistoryLayout} />
                      <PrivateRoute path='/order-details' component={OrderDetail} pageProps={props} />
                      <PrivateRoute path='/order-proposals' component={OrderProposalLayout} />
                      <PrivateRoute path='/order-proposal/details' component={OrderProposalDetailsLayout} pageProps={props}/>
                      <PublicRouter path='/help' component={Help} />
                      <PublicRouter path='/pricat' component={Pricat} />
                      <PublicRouter path='/terms-and-conditions' component={TermsLayout} />
                      <PublicRouter path='/privacy-policy' component={PolicyLayout} />
                    </MainRoutes>
                  )
                  : <PublicRouter default path='/404' component={NotFound} />
              );
            }))
          : <PublicRouter default path='/404' component={NotFound} />}
        {/* Public routes */}
        <PublicRouter default path='/404' component={NotFound} />
        <PublicRouter path='/login' component={Login} />
        <PrivateRoute path='/impersonate' component={ImpersonateUser} />
        <PublicRouter path='/help' component={Help} />
        <PublicRouter path='/forgot-password' component={ForgotPassword} />
        <PublicRouter path='/create-password' component={CreatePassword} />
        <PublicRouter path='/password-reset' component={ResetPassword} />
        <PublicRouter path='/terms-and-conditions' component={TermsLayout} />
        <PublicRouter path='/privacy-policy' component={PolicyLayout} />
        <PublicRouter path='/switch-account' component={SwitchAccount} />
      </Router>
    </>
  );
};
export default App;
